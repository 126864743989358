const userRoutes = [
  {
    path: '/user',
    components: {
      navbar: () => import('@/components/section/NavBar.vue'),
      default: () => import('@/components/layout/RouterView.vue'),
    },
    children: [
      {
        path: '',
        redirect: { name: 'home' },
      },
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ './Home.vue'),
        meta: { requireValidEmail: false },
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ './Profile.vue'),
        meta: { requireValidEmail: false },
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ './Settings.vue'),
        meta: { requireValidEmail: false },
      },
      {
        path: 'email-not-verified',
        name: 'verify-email',
        component: () => import(/* webpackChunkName: "verify-email" */ './VerifyEmail.vue'),
        meta: { requireValidEmail: false },
      },
    ],
  },
];

export default userRoutes;
