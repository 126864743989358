<template>
  <div>
    <h3>Quiz Table</h3>

    <button @click.prevent="downloadAll" class="button-primary">Download All Quizes</button>

    <div v-if="selectedCount">
      <button @click.prevent="deleteSelected" class="button-danger">Delete selected</button>
      <button @click.prevent="downloadSelected" class="button-primary">Download Selected</button>
    </div>
    <QuizUpload />
    <table class="u-full-width">
      <thead>
        <tr>
          <th></th>
          <th>
            <input
              type="checkbox"
              name="checkAll"
              id="checkAll"
              :checked="allChecked"
              @change="checkAll"
            />
          </th>
          <th>Question</th>
          <th>Options</th>
          <th>Allow Multiple?</th>
          <th>Ans</th>
          <th>Explanation</th>
          <th>Point</th>
          <th>Author</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="9" v-if="loading">Loading...</td>
        </tr>
        <tr v-for="(quiz,index) in quizes" :key="index">
          <td>{{index+1}}</td>
          <td>
            <input
              type="checkbox"
              :checked="quiz.checked"
              :id="'c-' + index"
              @change="updateChecked(index)"
            />
          </td>
          <td>{{quiz.Question}}</td>
          <td>{{quiz.Options}}</td>
          <td>{{quiz.AllowMultipleAnswers?'Yes':'No'}}</td>
          <td>{{quiz.Answers}}</td>
          <td>{{quiz.AnswersExplanation}}</td>
          <td>{{quiz.Point}}</td>
          <td>{{quiz.Author}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import QuizUpload from './components/QuizUpload.vue';

const { Parser } = require('json2csv');

export default {
  name: 'QuizList',
  components: { QuizUpload },
  data() {
    return {};
  },
  computed: {
    ...mapState('staging/quiz', {
      quizes: state => state.quizes,
      loading: state => state.loading,
    }),
    ...mapGetters('staging/quiz', {
      selectedCount: 'selectedCount',
      allChecked: 'allChecked',
    }),
  },

  methods: {
    ...mapMutations('staging/quiz', {
      checkAll: 'checkAllQuizes',
      updateChecked: 'updateSelectedQuiz',
    }),
    ...mapActions('staging/quiz', {
      deleteSelected: 'deleteSelectedQuizes',
    }),

    downloadSelected() {
      const selected = this.$store.getters['staging/quiz/getSelected'];

      this.convertToCsV(selected);
      // Deselect selected
      this.$store.commit('staging/quiz/deselectQuizes');
    },
    downloadAll() {
      this.convertToCsV(this.quizes);
    },

    convertToCsV(selectedQuizesArray) {
      /*
          Manipulating 'this.quizes' a state object  throws errors.
          This converts params back to normal object
      */
      const quizes = JSON.parse(JSON.stringify(selectedQuizesArray));
      if (quizes && quizes.length) {
        quizes.map((item) => {
          const quiz = item;
          // Delete Checked property
          delete quiz.checked;
          /*
              Hack for json2csv to format array field to spec.
              Json2csv package does not have an API to *modify!?*
              field (array in this case) values, hence, this hack. ):
          */
          if (quiz && Object.getOwnPropertyNames(quiz).length > 0) {
            // Get the key/field of the quiz
            const props = Object.keys(quiz);
            // Loop tru and check for array field type
            props.forEach((key) => {
              const val = quiz[key];
              if (Array.isArray(val)) {
                if (val !== '' || typeof val !== 'undefined') {
                  // Join the the values with eol (CRLF?)
                  const result = val.join('\n');
                  // Assign the result back to the key
                  quiz[key] = result;
                  return quiz;
                }
              }
              return quiz;
            });
          }
          return quiz;
        });

        const parser = new Parser({ doubleQuote: '' });
        const csv = parser.parse(quizes);
        // Grab generated csv and write to output stream
        const blob = new Blob([csv], { type: 'text/csv' });
        this.download(blob);
      }
    },

    download(blob) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // Filename
      a.download = 'wakaru-quizes.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
  },

  created() {
    if (!this.quizes || (this.quizes.length === 0)) this.$store.dispatch('staging/quiz/getAllQuizes');
  },
};
</script>

<style scoped stylus>
.upload-wrapper small {
  font-size: 70%;
}
</style>
