import firebase from './firebase.config';
import 'firebase/auth';

const authStore = {
  state: {
    user: {
      displayName: '',
      photoURL: '',
      email: '',
      emailVerified: false,
      phoneNumber: '',
      uid: '',
      providerData: [],
      claims: null,
    },
    isLoggedIn: false,
    isInitialized: false,
  },
  getters: {
    currentUser: () => firebase.auth().currentUser,
  },
  mutations: {
    setUser(state, user) {
      if (user !== null) {
        state.user.displayName = user.displayName;
        state.user.phoneNumber = user.phoneNumber;
        state.user.photoURL = user.photoURL;
        state.user.email = user.email;
        state.user.emailVerified = user.emailVerified;
        state.user.uid = user.uid;
        state.user.providerData = user.providerData;
        state.user.claims = user.claims;
      }
      state.isLoggedIn = (user !== null);
      state.isInitialized = true;
    },
    resetUser(state) {
      state.user = {};
      state.isLoggedIn = false;
      state.isInitialized = true;
    },
    setGravatarImage(state, url) {
      state.user.photoURL = url;
    },
  },
  actions: {
    signin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signInWithEmailAndPassword(payload.email, payload.password).then((res) => {
            commit('setUser', res.user);
            resolve(res.user);
          }).catch((err) => {
            reject(err);
          });
      });
    },
    fetchUser({ commit, dispatch }, user) {
      if (user) {
        commit('setUser', user);
        dispatch('getGravatarImage').catch(() => { /* ignore */ });
        dispatch('theme/initTheming', null, { root: true }).catch(() => { /* ignore */ });
      } else {
        commit('resetUser');
      }
    },
    getGravatarImage({ commit, state }) {
      if (!state.user.photoURL) {
        let hash = 'd41d8cd98f00b204e9800998ecf8427e'; // md5 hash for an empty string
        if (state.user.claims && state.user.claims.md5) hash = state.user.claims.md5;
        commit('setGravatarImage', `https://www.gravatar.com/avatar/${hash}?d=identicon`);
      }
    },
    register({ commit }, payload) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .createUserWithEmailAndPassword(payload.email, payload.password).then((res) => {
            if (res.user && payload.displayName) {
              res.user.updateProfile({
                firstName: payload.firstName,
                lastName: payload.lastName,
                displayName: payload.displayName,
              });
            }
            // Send Email Confirmation
            res.user.sendEmailVerification();
            commit('setUser', res.user);

            resolve(res.user);
          }).catch((err) => {
            reject(err);
          });
      });
    },
    resendVerificationEmail() {
      return new Promise((resolve, reject) => {
        const user = firebase.auth().currentUser;
        if (!user) {
          reject();
        }
        user.sendEmailVerification().then(resolve()).catch(() => { reject(); });
      });
    },
    signOut({ commit }) {
      return new Promise((resolve, reject) => {
        firebase.auth().signOut().then(() => { commit('resetUser'); resolve(true); }).catch(() => { reject(); });
      });
    },
  },
};

export default authStore;
