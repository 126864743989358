import QuizList from '../QuizList.vue';

const quizRoutes = [
  {
    path: 'quiz',
    component: () => import('../Quiz.vue'),
    children: [
      {
        path: 'list',
        name: 'list-quiz',
        component: QuizList,
      }, {
        path: '',
        redirect: 'list',
      },
    ],
  },
];

export default quizRoutes;
