import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import store from '@/store';
import settings from '@/app.config';
import { setUserToken } from '@/util/HttpRequest/api.backend';

// initialization routine after new sign in
function initAfterLogin() {
  store.dispatch('book/getFavoriteBooks').catch(() => { /* ignore */ });
}

// cleanup after user sign out
function resetAfterLogout() {
  store.commit('book/setFavoriteBooks', []);
  store.commit('test/setTests', []);
}

export function checkAuthStatus() {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().onIdTokenChanged(async (user) => {
        // add user's id token to the header of all http requests to the backend
        let idToken = '';
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          idToken = idTokenResult.token;
          user.claims = idTokenResult.claims;
        }
        setUserToken(idToken);

        // add user info to store
        await store.dispatch('auth/fetchUser', user);
        if (user) initAfterLogin(); else resetAfterLogout();
        resolve();
      });
    } catch (e) {
      reject(e);
    }
  });
}

// Initialize Firebase
firebase.initializeApp(settings.firebaseConfig);
firebase.analytics();
checkAuthStatus();

export default firebase;
