import mainRoutes from './main/router';
import authRoutes from './auth/router';
import bookRoutes from './book/router';

const publicRoutes = [
  ...mainRoutes,
  ...bookRoutes,
  ...authRoutes,
];

export default publicRoutes;
