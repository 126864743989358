import BackendRequest from '@/util/HttpRequest/api.backend';
import { safeJsonParse } from '@/util/string.helper';

const availableColors = [
  {
    theme: 'color_theme_blue',
    color: '#33C3F0',
  },
  {
    theme: 'color_theme_green',
    color: 'green',
  },
  {
    theme: 'color_theme_orange',
    color: 'orange',
  },
];

const themeStore = {
  namespaced: true,
  state: {
    themeColor: safeJsonParse(localStorage.getItem('w_theme')) || availableColors[0],
    mode: (localStorage.getItem('w_theme_mode') === 'true'),
    colors: [],
  },

  getters: {
    themeColor: state => state.themeColor,
    mode: state => state.mode,
  },

  mutations: {
    setColors(state, colors) {
      state.colors = colors;
    },
    setThemeMode(state, isDarkMode) {
      state.mode = isDarkMode;
      localStorage.setItem('w_theme_mode', state.mode);
    },
    setThemeColor(state, color) {
      const themeColor = availableColors.find(x => x.color === color);
      if (themeColor) state.themeColor = themeColor;
      localStorage.setItem('w_theme', JSON.stringify(state.themeColor));
    },
  },

  actions: {
    getColors({ commit }) {
      const colors = availableColors.map(c => c.color);
      commit('setColors', colors);
    },
    initTheming({ commit }) {
      BackendRequest.get('/users/me').then((res) => {
        const setting = res.data.settings;
        if (setting) {
          if (setting.color_theme) commit('setThemeColor', setting.color_theme.color);
          commit('setThemeMode', setting.dark_mode);
        }
      });
    },
    updateTheme({ state }) {
      const setting = {
        settings: {
          color_theme: state.themeColor,
          dark_mode: state.mode,
        },
      };
      BackendRequest.put('/users/setting', JSON.stringify(setting)).catch(() => {});
    },
  },
};

export default themeStore;
