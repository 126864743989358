<script>
import Vue from 'vue';
import NProgress from 'nprogress';
import router from '@/views/router';
import 'nprogress/nprogress.css';

// Configure the loading progress bar
NProgress.configure({ showSpinner: false, parent: '#app' });

router.beforeEach((to, from, next) => {
  // Start loading bar if the target route is different and never registered
  if (to.path !== from.path && !Vue.component(to.name)) {
    NProgress.start();
    // register the component by passing an empty option
    if (to.name) Vue.component(to.name, {});
  }
  next();
});

router.afterEach(() => {
  // Complete animation and remove loading bar after routing
  NProgress.done();
});

export default { name: 'nprogress' };

</script>

<style lang="stylus">
#nprogress .bar
  background-color: var(--color_secondary)

</style>
