<template>
  <div id="app" :class="[{site_theme_dark: mode}, themeColor.theme]" class="site container-fluid d-flex flex-column justify-content-between m-0 p-0">
    <router-view class="site_nav" name="navbar" />
    <main class="site_main flex-grow-1">
      <router-view />
    </main>
    <router-view class="site_footer" name="footer" />
    <Copyright class="site_copyright" />
    <BackToTop />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Copyright from '@/components/section/Copyright.vue';
import BackToTop from '@/components/ui/BackToTop.vue';

export default {
  components: {
    Copyright, BackToTop,
  },
  computed: {
    ...mapGetters('theme', ['themeColor', 'mode']),
  },
};

</script>

<style lang="stylus">

.site
  position relative
  width 100%
  min-height 100vh
  color var(--color_on_background)
  background-color var(--color_background)
  .site_nav
    &, .dropdown-menu, .navbar_lower
      color var(--color_on_secondary)
      background-color var(--color_primary)
    .b-nav-dropdown
      a[aria-expanded="true"]::after
        transform rotate(180deg)
  .site_footer
    color var(--color_on_primary)
    background-color var(--color_primary_variant)
  .site_copyright
    color var(--color_on_primary)
    background-color var(--color_primary)

</style>
