/**
 * Common Helper Functions for manipulating page DOM
 */

// Set page document title
export default function setPageTitle(title) {
  let pageTitle = 'Wakaru.Org';
  if (title) pageTitle += ` - ${title}`;
  document.title = pageTitle;
}
