import Vue from 'vue';
import VueRouter from 'vue-router';
import publicRoutes from './public/router';
import protectedRoutes from './protected/router';

Vue.use(VueRouter);

const routes = [
  ...publicRoutes,
  ...protectedRoutes,
  {
    path: '*',
    redirect: { name: 'notfound' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let scrollPos = { x: 0, y: 0 };
    if (savedPosition) scrollPos = savedPosition;
    if (to.hash) scrollPos = { selector: to.hash };
    return scrollPos;
  },
});

export default router;
