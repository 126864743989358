import Vue from 'vue';
import setPageTitle from '@/util/dom.helper';
import { loadFileAsync, getFilenameWithoutExt } from '@/util/file.helper';

const markdown = {
  name: 'markdown',

  render(h) {
    return h(this.getLayout, {
      class: [
        `content_${this.slotKey}`,
      ],
      props: {
        frontmatter: this.frontmatter,
      },
    }, this.$slots[this.slotKey]);
  },

  props: {
    pagePath: String,
    slotKey: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    frontmatter() {
      return this.$parent.$options.$frontmatter || {};
    },

    getLayout() {
      const { layout } = this.frontmatter;
      return layout ? (() => loadFileAsync(layout)) : 'div';
    },
  },

  mounted() {
    setPageTitle(this.frontmatter.title);
  },

  beforeDestroy() {
    setPageTitle();
  },
};

// Lazy load all components within the global directory
const requireComponent = require.context(
  // The relative path of the components folder
  '@/components/global',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /\.(vue|js)$/,
  // lazy load asynchronously
  'lazy',
);

// Register the components globally
requireComponent.keys().forEach((fileName) => {
  requireComponent(fileName).then((config) => {
    const componentName = getFilenameWithoutExt(fileName, ['vue', 'js']);
    const componentConfig = config.default || config;
    Vue.component(componentConfig.name || componentName, componentConfig);
  }).catch(() => {});
});

// Register main <markdown> component
Vue.component(markdown.name, markdown);

export default markdown;
