import { isEmpty, extend } from '../util';

/**
 * Constant strings definition
 */
export const GET = 'GET';
export const POST = 'POST';
export const DELETE = 'DELETE';
export const PUT = 'PUT';
export const TEXT = 'TEXT';
export const JSON = 'JSON';

/**
 * Default configuration options for Ajax Request
 */
export const DefaultConfig = {
  // Can be any of 'GET', 'POST', 'DELETE' or 'PUT'. It is case insensitive. 'GET' is default.
  method: GET,
  // Custom request headers
  headers: {
    Accept: 'application/json, text/plain, */*',
  },
  // Timeout in milliseconds. Set 0 for no timeout. 20s is default
  timeout: 20000,
  // Can be either 'JSON' or 'TEXT'. Case sensitivity handled internally. 'JSON' is default.
  responseType: JSON,
  // CORS (cross-site Access-Control). false by default
  withCredentials: false,
  // Common prefix of request url
  baseUrl: '',
  // Validates HTTP response status code. It can be either a Boolean Function or null.
  // Response promise is resolved when validateStatus() returns true (or is set to null or undefined).
  // The promise is rejected otherwise. Status code 2xx returns OK response by default.
  validateStatus: function validate(status) {
    return status >= 200 && status < 300;
  },
};

/**
 * Error object with extendable properties
 *
 * @param {String} message Error message
 * @param {Object} property Additional properties
 */
function AjaxError(message, property = {}) {
  extend(this, property);
  this.message = message;
}
AjaxError.prototype = new Error();

/**
 * Expose Ajax (Asynchronous JavaScript And XML) Request Functions
 *
 * @param {Object} inConfig Configuration settings
 * @return {any} Returns an object with helper functions for making HTTP Requests
 */
const Ajax = function Ajax(inConfig) {
  // XMLHttpRequest handle
  let pXhr = null;
  // Local configuration setting. Initialize it first to default
  const pConfig = {};
  extend(pConfig, DefaultConfig);
  // Then, merge the specified configuration with the local setting
  if (inConfig) extend(pConfig, inConfig);

  // Helper function to create an extended error object overloaded with more properties received from the server
  function extendError(msg, url, xhr, config, data = {}) {
    const error = {
      data,
      status: (xhr ? xhr.status : 0),
      statusText: (xhr ? xhr.statusText : ''),
      url,
      config,
    };
    return new AjaxError(msg, error);
  }

  // Send asynchronous request
  function sendAsyncRequest(xhr, url, config, data) {
    const xhrPromise = new Promise((resolve, reject) => {
      if (!xhr || isEmpty(url)) {
        reject(extendError(`Wrong parameter, either xhr is null or url is empty. xhr: ${xhr}, url: ${url}`,
          url, xhr, config));
      } else {
        xhr.open(config.method, url, true);

        // Set response type. Only lower case is accepted here
        xhr.responseType = config.responseType.toLowerCase();
        // Set headers
        Object.keys(config.headers).forEach((key) => {
          xhr.setRequestHeader(key, config.headers[key]);
        });

        // Set CORS / withCredentials
        if (config.withCredentials) xhr.withCredentials = true;

        // Handle timeout
        if (config.timeout) {
          xhr.timeout = config.timeout;
          xhr.ontimeout = function handleTimeout() {
            reject(extendError('Request Timeout', url, xhr, config));
          };
        }

        // Handle browser request cancellation (as opposed to a manual cancellation)
        xhr.onabort = function handleAbort() {
          reject(extendError('Request Aborted', url, xhr, config));
        };

        // Handle error
        xhr.onerror = function handleError() {
          // Real errors are hidden by the browser. Only network error is reported.
          reject(extendError('Network Error', url, xhr, config));
        };

        // Handle readyState changes
        xhr.onreadystatechange = function readyState() {
          // completed
          if (xhr && xhr.readyState === XMLHttpRequest.DONE) {
            // Success when status is not 0. Resolve promise.
            // Error when status is 0. It is handled by onerror callback.
            if (xhr.status !== 0) {
              let responseStr = '';
              if (isEmpty(xhr.responseType) || xhr.responseType.toUpperCase() === TEXT) {
                responseStr = xhr.responseText;
              } else if (xhr.responseType.toUpperCase() === JSON) {
                responseStr = xhr.response;
              } else {
                /* other response types not supported. do nothing */
              }

              // Resolve or reject based on validateStatus response
              if (!config.validateStatus || config.validateStatus(xhr.status)) {
                resolve({
                  data: responseStr, status: xhr.status, statusText: xhr.statusText, url, config,
                });
              } else {
                reject(extendError('Status validation failed', url, xhr, config, responseStr));
              }
            }

          // aborted through manual cancellation
          } else if (xhr && xhr.readyState === XMLHttpRequest.UNSENT) {
            reject(extendError('Request Cancelled', url, xhr, config));

          // other states, just return.
          } else {
            /* do nothing */
          }
        };

        // prepare data payload for POST & PUT requests
        const body = ([POST, PUT].includes(config.method.toUpperCase())) ? data : null;
        // dispatch the request
        xhr.send(body);
      }
    });

    return xhrPromise;
  }

  return {
    // Generic request method
    request: function request(method, url, config, data = {}) {
      if (config) extend(pConfig, config);
      pConfig.method = method;
      if (window && window.XMLHttpRequest) pXhr = new XMLHttpRequest();

      return sendAsyncRequest(pXhr, `${pConfig.baseUrl}${url}`, pConfig, data);
    },

    // Create (Post) method of CRUD
    post: function post(url, data, config) {
      return this.request(POST, url, config, data);
    },

    // Read (Get) method of CRUD
    get: function get(url, config) {
      return this.request(GET, url, config);
    },

    // Update (Put) method of CRUD
    put: function put(url, data, config) {
      return this.request(PUT, url, config, data);
    },

    // Delete method of CRUD
    delete: function del(url, config) {
      return this.request(DELETE, url, config);
    },

    // Cancel ongoing request
    abort: function abort() {
      if (pXhr) pXhr.abort();
    },

    // helper function to set a new configuration option
    setConfig: function setConfig(config) {
      if (config) extend(pConfig, config);
    },

    // Return the current configuration option
    getConfig: function getConfig() {
      return pConfig;
    },
  };
};

export default Ajax;
