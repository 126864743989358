import BackendRequest from '@/util/HttpRequest/api.backend';
import { shuffleArray } from '@/util/json2csv-array.transform';

const bookStore = {
  state: {
    books: [],
    categories: [],
    favoriteBooks: [],
  },

  mutations: {
    setAllBooks(state, data) {
      if (data && data.books) state.books = [...data.books];
    },
    addBooks(state, books) {
      if (books && books.length) {
        books.forEach((book) => {
          // update if the book is already in the array or prepend otherwise
          const idx = state.books.findIndex(item => item.id === book.id);
          if (idx >= 0) state.books[idx] = book;
          else state.books.unshift(book);
        });
      }
    },
    updateBooks(state, books) {
      if (books && books.length) state.books = state.books.map(x => books.find(y => y.id === x.id) || x);
    },
    removeBooks(state, bookIds) {
      if (bookIds && bookIds.length) state.books = state.books.filter(x => bookIds.indexOf(x.id) < 0);
    },
    setBookCategories(state, categories) {
      if (categories && categories.length) state.categories = categories;
    },
    setFavoriteBooks(state, fBooks) {
      state.favoriteBooks = fBooks;
    },
    addFavoriteBooks(state, fBookIds) {
      if (fBookIds && fBookIds.length) {
        const fBooks = state.books.filter(x => fBookIds.indexOf(x.id) >= 0);
        state.favoriteBooks.unshift(...fBooks);
      }
    },
    removeFavoriteBooks(state, fBookIds) {
      if (fBookIds && fBookIds.length) state.favoriteBooks = state.favoriteBooks.filter(x => fBookIds.indexOf(x.id) < 0);
    },
  },

  actions: {
    async getAllBooks({ commit }) {
      const url = '/books/read';
      return new Promise((resolve, reject) => {
        BackendRequest.get(url)
          .then((res) => {
            commit('setAllBooks', res.data);
            resolve();
          })
          .catch(() => reject());
      });
    },
    async getBookById({ dispatch }, bookId) {
      return new Promise((resolve, reject) => {
        dispatch('getBooksByIds', [bookId]).then((books) => {
          const book = (books && books.length) ? books[0] : {};
          resolve(book);
        }).catch(() => reject());
      });
    },
    async getBooksByIds({ dispatch, commit, state }, bookIds) {
      return new Promise((resolve, reject) => {
        let books = state.books.filter(x => bookIds.indexOf(x.id) >= 0);
        if (books.length === bookIds.length) resolve(books);
        else {
          const strBookIds = bookIds.join(',');
          const url = `/books/read/${strBookIds}`;
          BackendRequest.get(url)
            .then((res) => {
              ({ books } = res.data);
              commit('addBooks', books);
              resolve(books);
            })
            .catch(() => reject());

          // get all books as well if local book array is empty
          if (state.books.length === 0) dispatch('getAllBooks').catch(() => { /* ignore */ });
        }
      });
    },
    async addBooks({ commit }, books) {
      const url = '/books/create';
      return new Promise((resolve, reject) => {
        BackendRequest.post(url, JSON.stringify(books))
          .then((res) => {
            commit('addBooks', res.data);
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    async updateBooks({ commit }, books) {
      const url = '/books/update';
      return new Promise((resolve, reject) => {
        BackendRequest.put(url, JSON.stringify(books))
          .then((res) => {
            commit('updateBooks', res.data);
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    async deleteBooks({ commit }, bookIds) {
      const strBookIds = bookIds.join(',');
      const url = `/books/delete/${strBookIds}`;
      return new Promise((resolve, reject) => {
        BackendRequest.delete(url)
          .then(() => {
            commit('removeBooks', bookIds);
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    getFavoriteBooks({ commit }) {
      return new Promise((resolve, reject) => {
        BackendRequest.get('/favbooks/read').then((res) => {
          commit('setFavoriteBooks', res.data.books);
          resolve();
        }).catch(() => reject());
      });
    },
    markAsFavorite({ dispatch, commit }, bookIds) {
      return new Promise((resolve, reject) => {
        const params = {
          book_ids: bookIds,
        };
        Promise.all([
          BackendRequest.post('/favbooks/create', JSON.stringify(params)),
          dispatch('getBooksByIds', bookIds),
        ]).then(() => {
          commit('addFavoriteBooks', bookIds);
          resolve();
        }).catch(() => reject());
      });
    },
    removeAsFavorite({ commit }, bookIds) {
      const strBookIds = bookIds.join(',');
      const url = `/favbooks/delete/${strBookIds}`;
      return new Promise((resolve, reject) => {
        BackendRequest.delete(url).then(() => {
          commit('removeFavoriteBooks', bookIds);
          resolve();
        }).catch(() => reject());
      });
    },
    removeAllFavorites({ commit }) {
      return new Promise((resolve, reject) => {
        BackendRequest.delete('/favbooks/all').then(() => {
          commit('setFavoriteBooks', []);
          resolve();
        }).catch(() => reject());
      });
    },
    async getAllCategories({ dispatch, commit, state }) {
      // ToDo: Remove hardcoding and get data from the backend
      if (!state.books.length) await dispatch('getAllBooks');
      const categories = [
        { id: 'recent', title: 'Recently Added', books: shuffleArray(state.books) },
        { id: 'popular_primary', title: 'Most Popular with Primary / Elementary Schools', books: shuffleArray(state.books) },
        { id: 'popular_midschl', title: 'Most Popular with Junior Secondary / Middle Schools', books: shuffleArray(state.books) },
        { id: 'popular_highschl', title: 'Most Popular with Senior Secondary / High Schools', books: shuffleArray(state.books) },
      ];
      commit('setBookCategories', categories);
    },
  },
};

export default bookStore;
