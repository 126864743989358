/**
 * Common Utility Functions
 * Some of the functions are copied from util.js in axios.
 * See (https://github.com/axios/axios/blob/master/lib/utils.js).
 */

const { toString } = Object.prototype;

/**
 * Determine if a value is an Array
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Array, otherwise false
 */
export function isArray(val) {
  return toString.call(val) === '[object Array]';
}

/**
 * Determine if a value is undefined
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if the value is undefined, otherwise false
 */
export function isUndefined(val) {
  return typeof val === 'undefined';
}

/**
 * Determine if a value is a String
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a String, otherwise false
 */
export function isString(val) {
  return typeof val === 'string';
}

/**
 * Determine if a value is a Number
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Number, otherwise false
 */
export function isNumber(val) {
  return typeof val === 'number';
}

/**
 * Determine if a value is a Boolean
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Boolean, otherwise false
 */
export function isBoolean(val) {
  return typeof val === 'boolean';
}

/**
 * Determine if a value is an Object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Object, otherwise false
 */
export function isObject(val) {
  return val !== null && typeof val === 'object';
}
/**
 * Determine if a object is has at least one property
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if the value is undefined, otherwise false
 */
export function isObjectEmpty(val) {
  return isObject(val) && !isUndefined(val) && Object.keys(val).length === 0 && val.constructor === Object;
}

/**
 * Determine if a value is a Date
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Date, otherwise false
 */
export function isDate(val) {
  return toString.call(val) === '[object Date]';
}

/**
 * Determine if a value is a Function
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Function, otherwise false
 */
export function isFunction(val) {
  return toString.call(val) === '[object Function]';
}

/**
 * Trim excess whitespace off the beginning and end of a string
 *
 * @param {String} str The String to trim
 * @returns {String} The String freed of excess whitespace
 */
export function trim(str) {
  return str.replace(/^\s*/, '').replace(/\s*$/, '');
}

/**
 * Checks if a string is empty or undefind or contains only white-space
 *
 * @param {String} str The string to check
 * @return {Boolean} True if empty or undefined or contains only white-space, False otherwise
 */
export function isEmpty(str) {
  return (!str || !trim(str));
}

/**
 * Extends an object dst by adding to it shallow copies of properties from object src.
 *
 * @param {Object} dst Destination object to be extended
 * @param {Object} src Source object from which properties will be copied
 * @param {Boolean} overwrite Determines if common properties should be overwritten or not. Default: true.
 * @return {Object} The extended value of object dst
 */
export function extend(dst, src, overwrite = true) {
  if (dst && src) {
    Object.keys(src).forEach((key) => {
      if (overwrite || !dst[key]) {
        if (isObject(src[key])) {
          if (!dst[key]) dst[key] = {};
          extend(dst[key], src[key]);
        } else dst[key] = src[key];
      }
    });
  }
  return dst;
}

/**
 * Checks if a specified mime type is for an image or not.
 *
 * @param {String} mimeType Mime type to check
 * @return {Boolean} true if the mime type is for an Image or false otherwise.
 */
export function isImage(mimeType) {
  let isImageType = false;

  if (mimeType) {
    switch (mimeType) {
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/gif':
      case 'image/tiff':
      case 'image/png':
        isImageType = true;
        break;

      default:
        break;
    }
  }

  return isImageType;
}

/**
 * Checks if a string is valid url.
 *
 * @param {String} url string to check
 * @return {Boolean} true if specified string is a valid URL or false otherwise
 *
 */
export function isUrl(url) {
  try {
    const validUrl = new URL(url);
    if (validUrl) return true;
  } catch (_) { /* ignore */ }
  return false;
}

/**
 * Returns a function that is only triggered at specified regular intervals.
 * It was mostly taken from Underscore.js Debounce function (https://davidwalsh.name/javascript-debounce-function).
 *
 * @param {Function} func The function that will be invoked on timeout.
 * @param {Object} wait Timeout interval in milliseconds.
 * @param {Boolean} immediate When true, trigger the function on the leading edge, instead of the trailing.
 * @param {Array} args The rest arguments that will be passed to the function.
 * @return {Function} A function that will be called on specified wait intervals.
 */
export function debounce(func, wait, immediate, ...args) {
  let timeout = null;

  return function debounced() {
    const context = this;
    const callNow = immediate && !timeout;
    const later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

module.export = {
  isArray,
  isUndefined,
  isString,
  isNumber,
  isBoolean,
  isObject,
  isObjectEmpty,
  isDate,
  isFunction,
  isEmpty,
  trim,
  extend,
  isImage,
  isUrl,
  debounce,
};
