const quizRoutes = [
  {
    path: 'book/:book_id/quiz',
    component: () => import('@/components/layout/RouterView.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'quiz-list' },
      },
      {
        path: 'list',
        name: 'quiz-list',
        props: true,
        component: () => import('./ListQuiz.vue'),
      },
      {
        path: 'new',
        name: 'quiz-new',
        props: true,
        component: () => import('./CreateQuiz.vue'),
      },
      {
        path: ':quiz_id',
        name: 'quiz-view',
        props: true,
        component: () => import('./ViewQuiz.vue'),
      },
      {
        path: ':quiz_id/edit',
        name: 'quiz-edit',
        props: true,
        component: () => import('./EditQuiz.vue'),
      },
    ],
  },
];

export default quizRoutes;
