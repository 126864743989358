/**
 * Generate a random string of specified length
 * @param {Number} length Length of the string to generate
 */
export default function generateRandomString(length = 15) {
  if (length <= 0) {
    throw new Error('length should be a positive number');
  }
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const xLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * xLength));
  }
  return result;
}

export function toSlug(url) {
  if (!url) return '';
  const arr = url.trim().toLowerCase().split(/[.,/#!$%^&*;:{}=\-|_'"`~() ]/g);
  return arr.filter(x => x).reduce((agg, u) => `${agg}-${u}`);
}

export function splitByTag(value, separator = ',') {
  if (!value) return [];
  if (typeof value !== 'string') { throw new Error('Value must be a string'); }
  if (value) {
    return value.split(separator);
  }
  return [];
}

/**
 * Return a string with the first letter in uppercase.
 * @param {String} str Input String to capitalize.
 */
export function capitalizeFirstLetter(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

/**
 * Compare two strings without checking their cases.
 * @param {String} strA The first string for comparison.
 * @param {String} strB The second string for comparison.
 * @returns Return 0 if the strings are the same, -1 if strA is less than str B or +1 otherwise.
 */
export function strSortCaseInsensitive(strA, strB) {
  const upA = strA.toUpperCase();
  const upB = strB.toUpperCase();
  if (upA < upB) return -1;
  if (upA > upB) return 1;
  return 0;
}

/**
 * Safely parse any string as JSON without throwing errors.
 * @param {String} str Input string to parse and convert to JSON
 * @returns Return parsed JSON object on success or null otherwise.
 */
export function safeJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch {
    return null;
  }
}
