<template>
  <div >
    <h3>List of Books</h3>
    <div v-if="books">
     <div v-if="selectedCount">
      <button @click.prevent="deleteSelected" class="btn btn-danger">Delete selected</button>
      <button @click.prevent="downloadSelected" class="btn btn-primary mx-2">Download selected ({{selectedCount}})</button>
     </div>
      <button @click.prevent="downloadAll" class="btn btn-primary my-2">Download All Books</button>
      <br />
      <router-link :to="{ name: 'new-book'}">Add New Book</router-link>

  <table class="table table-bordered">
      <thead>
        <tr>
          <th>
           <input
              type="checkbox"
              name="checkAll"
              id="checkAll"
              :checked="allChecked"
              @change="checkAll"
            />
          </th>
          <th></th>
          <th></th>
          <th>Title</th>
          <th>Authors</th>
          <th>Publishers</th>
          <th>Published date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
        <td colspan="7" class="text-center">
        <b-spinner></b-spinner>
        </td>
        </tr>
        <tr v-for="(book,index) in books" :key="book.id">
          <template  v-if="book.id!==editRowId">
          <td>
            <div>
             <input
              type="checkbox"
              :checked="book.checked"
              :id="'c-' + index"
              @change="updateChecked(index)"
            /> |
             <a href="javascript:;" @click="edit(book.id, index)" > Edit</a>
             |
             <a href="javascript:;" @click="deleteBook(book.id)" > Delete</a>
            </div>
            <div>
             <router-link :to="{ name: 'addQuiz', params: { id: book.id }}">Add quiz</router-link>
             |
             <router-link :to="{ name: 'TestInstruction', params: { book_id: book.id }}">Take Test</router-link>
            </div>
          </td>
          <td>{{index+1}}</td>
          <td> <img class="thumbnail" :src="getBookImage(book.coverimage)" :alt="book.title +'s art'" /></td>
          <td><p class="truncate">  {{book.title}}</p></td>
          <td>{{book.author.join(',')}}</td>
          <td>{{book.publisher.join(',')}}</td>
          <td>{{book.published_year}}</td>
          </template>
              <template  v-else>
          <td>
               <a href="javascript:;" @click="updateBook()"  > Save</a> |
               <a href="javascript:;" @click="cancelUpdate()" > X</a>
          </td>
          <td>{{index+1}}</td>
          <td> <img class="thumbnail" :src="getBookImage(book.coverimage)" :alt="book.Title +'s art'" /></td>
          <td>  <input v-model="editItem.Title"> </td>
          <td><input v-model="editItem.SubTitle"></td>
          <td><textarea v-model="editItem.Abstract"></textarea></td>
          <td><input v-model="editItem.Author" placeholder="Separate Author by comma ,"> </td>
          <td><input v-model="editItem.PublishDate" placeholder="Published Date"> </td>
          <td><input v-model="editItem.Edition" placeholder="Edition"> </td>
          <td><input v-model="editItem.Language" placeholder="Language"> </td>
          <td><input v-model="editItem.Publisher" placeholder="Publishers separated by comma ,"> </td>
          </template>
        </tr>
      </tbody>
    </table>

    </div>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import transform from '@/util/json2csv-array.transform';
import { downloadFile as download } from '@/util/file.helper';

const { Parser } = require('json2csv');

export default {
  name: 'BookList',
  data() {
    return {
      selectMultiple: false,
      isEditing: false,
      editRowId: null,
      editItem: null,
      loading: false,
    };
  },
  computed: {
    ...mapState('staging/book', {
      books: state => state.books,
    }),
    ...mapGetters('staging/book', {
      selectedCount: 'selectedCount',
      allChecked: 'allChecked',
      bookCount: 'bookCount',
    }),

  },

  methods: {
    ...mapMutations('staging/book', {
      checkAll: 'checkAllBooks',
      updateChecked: 'updateSelectedBook',
    }),
    ...mapActions('staging/book', {
      deleteSelected: 'deleteSelectedBooks',
      deleteBook: 'deleteBook',
    }),
    toggleMultiple() {
      this.selectMultiple = !this.selectMultiple;
    },
    getBookImage(imageUrls) {
      if (imageUrls) {
        if (Array.isArray(imageUrls)) {
          return imageUrls[0];
        }
      }
      return '';
    },
    edit(bookId, rowIndex) {
      if (!bookId && rowIndex) {
        return false;
      }
      this.isEditing = true;
      const item = this.books.find(x => x.id === bookId);
      if (!item) {
        return false;
      }
      // Create a new book Object to avoid mutations outside vuex store
      this.editItem = { ...item };
      this.editRowId = bookId;
      return true;
    },
    updateBook() {
      this.$store.dispatch('staging/book/updateBook', this.editItem).then(this.cancelUpdate());
    },
    cancelUpdate() {
      this.editItem = null;
      this.editRowId = null;
      this.isEditing = false;
    },
    downloadSelected() {
      const selected = this.$store.getters['staging/book/getSelected'];

      this.convertToCsV(selected);
      // Deselect selected
      this.$store.commit('staging/book/deselectBooks');
    },
    downloadAll() {
      this.convertToCsV(this.books);
    },
    convertToCsV(selectedBooksArray) {
      /*
          Manipulating 'this.books' a state object  throws errors.
          This converts params back to normal object
      */
      const books = JSON.parse(JSON.stringify(selectedBooksArray));
      if (books && books.length) {
        books.map((item) => {
          const book = item;
          // Delete Checked property
          delete book.checked;
          transform(book);

          return book;
        });

        const parser = new Parser({ doubleQuote: '' });
        const csv = parser.parse(books);
        // Grab generated csv and write to output stream
        const blob = new Blob([csv], { type: 'text/csv' });
        download(blob, 'wakaru-book.csv');
      }
    },
    getBooks() {
      this.loading = true;
      this.$store.dispatch('staging/book/getAllBooks').then(() => {
        this.loading = false;
      }).catch(this.loding = true);
    },
  },

  created() {
    if (!this.books || (this.books.length === 0)) this.getBooks();
  },
};
</script>

<style scoped lang="stylus">
.thumbnail
    width: 54px
    height:74px

.truncate
   width:280px
   white-space: nowrap
   overflow: hidden
   text-overflow: ellipsis
</style>
