const mainRoutes = [
  {
    path: '/',
    components: {
      navbar: () => import('@/components/section/NavBar.vue'),
      footer: () => import('@/components/section/Footer.vue'),
      default: () => import('@/components/layout/RouterView.vue'),
    },
    children: [
      {
        path: '',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ './Index.vue'),
        meta: { makeFullWidth: true },
      },
      {
        path: '/(|index|index.htm|index.html)',
        redirect: { name: 'index' },
      },
      {
        path: '/not-found',
        name: 'notfound',
        component: () => import(/* webpackChunkName: "notfound" */ './404.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ './About.vue'),
      },
      {
        path: '/terms-of-use',
        name: 'tou',
        component: () => import(/* webpackChunkName: "tou" */ './tou.md'),
      },
      {
        path: '/privacy-policy',
        name: 'policy',
        component: () => import(/* webpackChunkName: "policy" */ './policy.md'),
      },
      {
        path: '/contact-us',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ './ContactUs.vue'),
      },
    ],
  },
];

export default mainRoutes;
