const authRoutes = [
  {
    path: '/auth',
    components: {
      navbar: () => import('./components/NavBar.vue'),
      default: () => import('@/components/layout/RouterView.vue'),
    },
    children: [
      {
        path: '',
        redirect: { name: 'login' },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ './Login.vue'),
        props: route => ({ query: route.query.redirect }),
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "logout" */ './Logout.vue'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ './Register.vue'),
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "forgot-password" */ './ForgotPassword.vue'),
      },
    ],
  },
];

export default authRoutes;
