import BackendRequest from '@/util/HttpRequest/api.backend';

const userStore = {
  namespaced: true,
  state: {
    users: [],
  },

  mutations: {
    setUsers(state, users) {
      if (users && users.length) state.users = [...users];
    },
  },

  actions: {
    // get all users
    getAllUsers({ commit }) {
      return new Promise((resolve, reject) => {
        BackendRequest.get('/users/read')
          .then((res) => {
            const { users } = res.data;
            commit('setUsers', users);
            resolve();
          })
          .catch(() => reject());
      });
    },

  },
};

export default userStore;
