import { isArray, isObject, isUndefined } from './util';

/**
 * Hack for json2csv to format array field to spec.
 * Json2csv package does not have an API to *modify!?*
 * field (array in this case) values, hence, this hack. ):
 * @param {Object} item Object to transform its array properties
*/
export default function tranform(item) {
  if (isObject(item) && Object.getOwnPropertyNames(item).length > 0) {
    // Get the key/field of the item
    const props = Object.keys(item);
    // Loop tru and check for array field type
    props.forEach((key) => {
      const val = item[key];
      //  We dont want to format undefined, empty and/or any other object
      if (!isUndefined(val) && val && isArray(val)) {
        if (val.length > 0) {
          // Join the the values with eol (CRLF?)
          const result = val.join('\n');
          // Assign the result back to the key
          item[key] = result;
        }
      }
    });
  }

  return item;
}

/**
 * Randomly shuffles the items within a given array using Durstenfeld shuffle algorithm.
 * The code is from https://stackoverflow.com/a/12646864.
 * @param {Array} array An array to shuffle
 */
export function shuffleArray(array) {
  const shuffled = array.slice(0);
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}
