/**
 * Dynamically register all Vuex module store files in this folder and all sub-folders
 *
 * Folders must follow below structure
 * ├── index.js         # This file
 * └── modulename       # This will be registered as the module namespace
 *       ├── store.js   # The module store logic containing state, getters, actions and mutations
 *       └── type.js    # (optional) Types constants
 */

export const modules = {};

// Find all files in this folder and its sub-folders with the filename 'store.js'
const requireModule = require.context('.', true, /store\.js$/);

requireModule.keys().forEach((fileName) => {
  const moduleDefinition = requireModule(fileName).default || requireModule(fileName);
  const moduleName = fileName.replace(/(^\.\/|\/store\.js$|\.js$)/g, '');
  modules[moduleName] = {
    // Modules are namespaced by default
    namespaced: true,
    ...moduleDefinition,
  };
});

/**
 * Dispatch a specified action to all modules that have it.
 *
 * @param {Object} store Vuex store object.
 * @param {String} actionName Name of the action to dispatch/call.
 */
export function dispatchActionToAllModules(store, actionName) {
  let namespaced = true;

  // loop thru all modules and dispatch for namespaced modules
  Object.keys(modules).forEach((moduleName) => {
    const moduleDefinition = modules[moduleName];
    if (moduleDefinition.actions && moduleDefinition.actions[actionName]) {
      if (moduleDefinition.namespaced) store.dispatch(`${moduleName}/${actionName}`);
      else namespaced = false;
    }
  });

  // dispatch for non-namespaced modules
  if (!namespaced) store.dispatch(actionName);
}

export default modules;
