import * as api from './api';

const quizStore = {
  namespaced: true,
  state: {
    quizes: [],
    loading: false,
  },
  getters: {

    getSelected: state => state.quizes.filter(item => item.checked),
    selectedCount: state => state.quizes.filter(item => item.checked).length,
    quizCount: state => state.quizes.length,
    allChecked: state => state.quizes.every(quiz => quiz.checked),
  },

  mutations: {
    setAllQuizes(state, quizes) {
      state.quizes = [...quizes];
    },
    addNewQuizes(state, quizes) {
      state.quizes.unshift(...quizes);
    },
    setUpdateQuizzes(state, quizzes) {
      for (let i = 0; i < quizzes.length; i += 1) {
        const element = quizzes[i];
        // Find Old Quiz with Id and replace with the updated values
        const oldQuiz = state.quizes.find(x => x.id === element.id);
        if (oldQuiz) {
          const index = state.quizes.indexOf(oldQuiz);
          state.quizes[index] = element;
        }
      }
      state.quizes = [...state.quizes];
    },
    removeSelectedQuizes(state) {
      state.quizes.filter(quiz => quiz.checked).map((item) => {
        if (item.checked) {
          state.quizes.splice(state.quizes.indexOf(item), 1);
        }
        return state.quizes;
      });
    },
    updateSelectedQuiz(state, index) {
      state.quizes[index].checked = !state.quizes[index].checked;
      state.quizes = [...state.quizes];
    },
    checkAllQuizes(state) {
      state.quizes = state.quizes.map(item => ({
        ...item,
        checked: !item.checked,
      }));
    },
    deselectQuizes(state) {
      const quizes = state.quizes.map(item => ({
        ...item,
        checked: false,
      }));
      state.quizes = [...quizes];
    },
    loading(state) {
      state.loading = true;
    },
    unLoading(state) {
      state.loading = false;
    },
  },
  actions: {
    async getAllQuizes({ commit }) {
      commit('loading');
      commit('setAllQuizes', await api.getAllQuizes().then(commit('unLoading')));
    },

    async deleteSelectedQuizes({ commit, state }) {
      const selectedQuizIds = [];
      state.quizes.map((item, index) => {
        if (item.checked) {
          selectedQuizIds.push(index);
        }
        return selectedQuizIds;
      });
      commit('removeSelectedQuizes');
      await api.deleteQuizes(selectedQuizIds);
    },

    async addMultipleQuizes({ commit }, json) {
      try {
        commit('addNewQuizes', json);
        // Send to remote server
        await api.addMultipleQuizes(json);
      } catch (error) {
        throw new Error('Invalid Json Object.');
      }
    },
    async updateQuizzes({ commit }, quizzes) {
      try {
        const allHasIds = quizzes.every(x => x.id);
        if (!allHasIds) {
          throw new Error('All Quizzes to update must have a valid ID');
        }
        // Send to remote server
        await api.updateQuiz(quizzes).then(
          commit('setUpdateQuizzes', quizzes),
        );
      } catch (error) {
        throw new Error('Invalid Json Object.');
      }
    },
  },
};


export default quizStore;
