import Vue from 'vue';

const vue = new Vue();

/**
 * Provide **Wrapper** around Bootstrap-vue toast component
 * @param {String} message Required. Message to show in toast
 * @param {String} title Optional Title of Toast
 * @param {String} type Optional. Type of Toast. Variants follow the standard Bootstrap v4 e.g
 * 'primary, primary, secondary, danger, success, warning, info
 */
function showToast(message, title = '', type = 'default') {
  if (message) {
    const nTitle = title || null;
    vue.$bvToast.toast(message, {
      title: nTitle,
      variant: type,
      solid: true,
      toaster: 'b-toaster-top-center',
      appendToast: true,
    });
  }
}
const toaster = {
  error: (message, title) => {
    showToast(message, title || 'An Error Occured', 'danger');
  },
  success: (message, title) => {
    showToast(message, title || 'Operation Successful', 'success');
  },
  info: (message, title) => {
    showToast(message, title || 'Information');
  },
};
Vue.prototype.$toast = toaster;
export default toaster;
