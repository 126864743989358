import HttpRequest from './index';

const baseUrl = 'https://api.wakaru.org/v1';

// create a new instance of HttpRequest
const HttpApiBackend = HttpRequest.create({ baseUrl });

// Include the authentication token of the signed in user in the header of all http requests
export function setUserToken(token) {
  const config = HttpApiBackend.getConfig();
  config.headers.Authorization = `Bearer ${token}`;
}

export default HttpApiBackend;
