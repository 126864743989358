import BookList from '../BookList.vue';

const bookRoutes = [
  {
    path: 'book',
    component: () => import('../Book.vue'),
    children: [
      {
        path: 'new',
        name: 'new-book',
        component: () => import('../NewBook.vue'),
      },
      {
        path: 'list',
        name: 'list-book',
        component: BookList,
      },
      {
        path: '',
        redirect: 'list',
      },
    ],
  },
];

export default bookRoutes;
