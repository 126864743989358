import BackendRequest from '@/util/HttpRequest/api.backend';

const fileStore = {
  state: {
    files: [],
  },
  mutations: {
    setAllFiles(state, files) {
      state.files = files;
    },
  },
  actions: {
    getFiles({ commit }, parentId = '') {
      let url = '/files/read';
      if (parentId) {
        url = `/files/read/${parentId}`;
      }
      return new Promise((resolve, reject) => {
        BackendRequest.get(url).then((res) => {
          const files = res.data.entries;
          commit('setAllFiles', files);
          resolve(files);
        }).catch(() => reject());
      });
    },
    addFile(context, payload) {
      const apiRequests = [];
      let url = '/files/create';
      if (payload) {
        if (payload.folderId) {
          url = `/files/create/${payload.folderId}`;
        }
        payload.files.map((file) => {
          const input = {
            name: file.name,
            content: file.src,
          };
          const request = BackendRequest.post(url, JSON.stringify(input));
          apiRequests.push(request);
          return file;
        });
      }
      return BackendRequest.all(apiRequests);
    },
    addFolder(context, payload) {
      let url = '/files/create';
      if (payload.parentId) {
        url = `/files/create/${payload.parentId}`;
      }
      return BackendRequest.post(`${url}?type=folder`, JSON.stringify({ name: payload.name }));
    },
    addShortcut(context, payload) {
      let url = '/files/create?type=shortcut';
      if (payload.parentId) {
        url = `/files/create/${payload.parentId}?type=shortcut`;
      }
      return BackendRequest.post(url, JSON.stringify({ name: `Shortcut to ${payload.shortcutUrl}`, url: payload.shortcutUrl }));
    },
    deleteFile(context, ids) {
      const url = `/files/delete/${ids}`;
      return BackendRequest.delete(url);
    },

  },
};


export default fileStore;
