import { Parser as JsonParser } from 'json2csv';
import CsvParser from 'csvtojson';
import generateRandomString from './string.helper';


/**
 * Download a file with specified name.
 * @param {Blob} file File to download
 * @param {String} name Name of file to download including file extension
*/
export function downloadFile(file, name = '') {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  const fName = name || file.name || generateRandomString();
  // Filename
  a.download = fName;
  document.body.appendChild(a);
  a.click();
  const URL = window.URL || window.webkitURL;
  setTimeout(() => { URL.revokeObjectURL(url); }, 4E4); // 40 seconds
}

/**
 * Convert specified JSON object into a CSV string.
 * @param {Object} json JSON data to convert
 * @returns {string} Comma-separated string that can be written into a file
 */
export function convertJson2Csv(json) {
  const parser = new JsonParser({ doubleQuote: '' });
  return parser.parse(json);
}

/**
 * Convert specified JSON object into a CSV string.
 * @param {Object} json JSON data to convert
 * @returns {string} Comma-separated string that can be written into a file
 */
export async function convertCsv2Json(csv) {
  const json = await CsvParser().fromString(csv);
  return json;
}

/**
 * Write a specified CSV data into an output file stream and trigger its download to users' PC.
 * @param {string} csv File content string in CSV format
 */
export function writeCsv2File(csv, filename) {
  const blob = new Blob([csv], { type: 'text/csv' });
  downloadFile(blob, filename);
}

/**
 * Read the content of a specified file and return the content as a promise.
 * @param {Object} oFile Object handle of the file to read
 * @returns {Promise} Resolve the file content
 */
export async function readFileContent(oFile) {
  return new Promise((resolve, reject) => {
    if (oFile) {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsText(oFile, 'UTF-8');
    } else reject();
  });
}

/**
 * Asynchronous dynamic import of a specified file or module.
 * Note: 'modulePath' must be a relative path to 'src/' directory.
 * E.g.: '@/assets/logo.png' or 'assets/logo.png'
 *
 * @param {String} modulePath Resolve path of the module. It must be relative to 'src/' path.
 */
export async function loadFileAsync(modulePath) {
  // ** Hack to avoid 'Cannot find module' error in webpack for dynamic import
  const mPath = modulePath.replace(/^@\//, '');
  // ** Hack to avoid "Cannot read property 'range' of null" in eslint-loader for template-curly-spacing rules
  // ** ESLint is temporarily disabled to make the hacks work
  return import('@/' + mPath); // eslint-disable-line prefer-template
}

/**
 * Return filename part of a specified fullpath with file extension.
 * @param {String} filePath Full path of the file
 */
export function getFilename(filePath = '') {
  return filePath.replace(/^.*[\\/]/, '');
}

/**
 * Return filename part of a specified fullpath withOUT file extension.
 * @param {String} filePath  Full path of the file
 * @param {Array of Strings} ext List of possible extensions. Only the matching one will be removed.
 */
export function getFilenameWithoutExt(filePath, ext = []) {
  let fileName = getFilename(filePath);
  if (ext.length) {
    const regex = new RegExp(`.(${ext.join('|')})$`, 'i');
    fileName = fileName.replace(regex, '');
  }
  return fileName;
}

/**
 * Return the extension of a specified file fullpath.
 * @param {String} filePath  Full path of the file
 */
export function getFileExtention(filePath) {
  const fileName = getFilename(filePath);
  return fileName.split('.').pop() || '';
}
