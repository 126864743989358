<template>
  <div>
    <div class="upload-wrapper">
      <label for="image_uploads">Choose file to upload (CSV)</label>
      <input
        type="file"
        id="file"
        name="file"
        class="button-primary"
        :accept="exts.join(',')"
        @change="upload($event.target.files)"
      />

      <p v-if="feedback">{{feedback}}</p>
    </div>
  </div>
</template>

<script>
const csv = require('csvtojson');

export default {
  name: 'QuizUpload',
  data() {
    return {
      feedback: null,
      exts: ['.csv'],
      fileTypes: ['text/csv', 'application/vnd.ms-excel'],
    };
  },
  methods: {
    upload(files) {
      if (!files) {
        this.setFeedback('No file selected');
        return;
      }
      const file = files[0];
      const isValid = this.validateExt(file);
      if (!isValid) {
        this.setFeedback('Invalid file extension');
      } else {
        this.setFeedback(
          'Valid file extension, Validating uploaded file... Please wait...',
        );
        this.processFile(file);
      }
    },
    processFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setFeedback('File Read. Converting to Json. Please wait...');
          this.convertToJSon(reader.result);
        };
        reader.readAsText(file);
      }
    },
    convertToJSon(csvText) {
      // FIXME move to utility
      csv({
        colParser: {
          Question: (item) => {
            if (!item) {
              this.setFeedback('Question must contain a value.');
              throw new Error();
            }
            return item;
          },
          Options: (item) => {
            if (!item) {
              this.setFeedback('Options must contain a value.');
              throw new Error();
            }
            const parsed = item.split('\n');
            if (!Array.isArray(parsed)) {
              this.setFeedback(
                'Options must be a collection/Array of strings.',
              );
              throw new Error();
            }
            return parsed; // Formated Array
          },
          Answers: (item) => {
            if (!item) {
              this.setFeedback('Answers must contain a value.');
              throw new Error();
            }
            const parsed = item.split('\n');
            if (!Array.isArray(parsed)) {
              this.setFeedback(
                'Answers must be a collection/Array of strings.',
              );
              throw new Error();
            }
            return parsed; // Formated Array
          },
        },
      })
        .fromString(csvText)

        .then((result) => {
          if (result) {
            // Filter records with book Id
            const filesToUpdate = result.filter(x => x.id);
            if (filesToUpdate && filesToUpdate.length) {
              this.updateQuizzes(filesToUpdate);
            }
            const filesToAdd = result.filter(x => !x.id);
            if (filesToAdd && filesToAdd.length) {
              this.addQuizzes(filesToAdd);
            }
          }
        });
    },
    updateQuizzes(quizzes) {
      if (quizzes) {
        this.$store.dispatch('staging/quiz/updateQuizzes', quizzes).then(
          this.setFeedback('Quizzes updated successfully'),
        );
      }
    },
    addQuizzes(quizzes) {
      if (quizzes) {
        this.$store.dispatch('staging/quiz/addMultipleQuizes', quizzes).then(
          this.setFeedback('Quizzes added successfully'),
        );
      }
    },
    validateExt(file) {
      if (file && file.name) {
        for (let i = 0; i < this.exts.length; i += 1) {
          const fileExt = file.name.split('.').pop();
          if (`.${fileExt}`.toLowerCase() === this.exts[i].toLowerCase()) {
            return true;
          }
        }
      }
      return false;
    },
    setFeedback(msg) {
      this.feedback = msg;
    },
  },
};
</script>

<style>
</style>
