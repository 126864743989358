import bookRoutes from './book/router';
import quizRoutes from './quiz/router';
import stagingRoutes from './staging/router';
import schoolRoutes from './school/router';

const backendRoutes = [
  {
    path: '/backend',
    components: {
      navbar: () => import('@/components/section/NavBar.vue'),
      default: () => import('@/components/layout/RouterView.vue'),
    },
    children: [
      {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ './Dashboard.vue'),
        children: [
          {
            path: '',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "overview" */ './Overview.vue'),
          },
          ...bookRoutes,
          ...quizRoutes,
          ...schoolRoutes,
        ],
      },
      ...stagingRoutes,
    ],
  },
];

export default backendRoutes;
