import HttpRequest from '@/util/HttpRequest';
import BackendRequest from '@/util/HttpRequest/api.backend';

const bookStore = {
  state: {
    books: [],
  },
  getters: {
    getSelected: state => state.books.filter(item => item.checked),
    selectedCount: state => state.books.filter(item => item.checked).length,
    bookCount: state => state.books.length,
    allChecked: state => state.books.every(book => book.checked),
  },

  mutations: {
    setAllBooks(state, data) {
      if (data && data.books) state.books = [...data.books];
    },
    addNewBook(state, book) {
      state.books.unshift(book);
    },
    setUpdateBook(state, book) {
      const oldBook = state.books.find(x => x.id === book.id);
      if (oldBook) {
        const bookIndex = state.books.indexOf(oldBook);
        if (bookIndex !== -1) {
          // Found
          state.books[bookIndex] = book;
        }
      }
    },
    removeSelectedBooks(state) {
      state.books
        .filter(book => book.checked)
        .map((item) => {
          if (item.checked) {
            state.books.splice(state.books.indexOf(item), 1);
          }
          return state.books;
        });
    },
    removeBook(state, bookId) {
      const book = state.books.find(item => item.id === bookId);
      if (book) {
        const index = state.books.indexOf(book);
        state.books.splice(index, 1);
      }
    },
    updateSelectedBook(state, index) {
      state.books[index].checked = !state.books[index].checked;
      state.books = [...state.books];
    },
    checkAllBooks(state) {
      state.books = state.books.map(item => ({
        ...item,
        checked: !item.checked,
      }));
    },
    deselectBooks(state) {
      const books = state.books.map(item => ({
        ...item,
        checked: false,
      }));
      state.books = [...books];
    },
  },
  actions: {
    async getAllBooks({ commit }) {
      const url = '/books/read';
      return new Promise((resolve, reject) => {
        BackendRequest.get(url)
          .then((res) => {
            commit('setAllBooks', res.data);
            resolve();
          })
          .catch(() => reject());
      });
    },
    lookupIsbn(context, isbn) {
      const url = `https://openlibrary.org/api/books?bibkeys=ISBN:${isbn}&jscmd=data&format=json`;

      return new Promise((resolve, reject) => {
        HttpRequest.get(url).then((res) => {
          const book = {};
          let isbn13 = '';
          let isbn10 = '';
          const key = `ISBN:${isbn}`;
          const jBook = res.data[key];
          if (jBook) {
            book.coverimage = [];
            book.title = jBook.title;
            book.subtitle = jBook.subtitle;
            book.published_year = jBook.publish_date;
            book.content_path = [jBook.url || ''];
            if (jBook.authors) book.author = jBook.authors.map(a => a.name);
            if (jBook.publishers) book.publisher = jBook.publishers.map(a => a.name);
            if (jBook.cover && jBook.cover.large) book.coverimage.push(jBook.cover.large);
            if (jBook.cover && jBook.cover.medium) book.coverimage.push(jBook.cover.medium);
            if (jBook.cover && jBook.cover.small) book.coverimage.push(jBook.cover.small);
            if (jBook.identifiers && jBook.identifiers.isbn_13) [isbn13] = jBook.identifiers.isbn_13;
            if (jBook.identifiers && jBook.identifiers.isbn_10) [isbn10] = jBook.identifiers.isbn_10;
            book.isbn_13 = isbn13;
            book.isbn_10 = isbn10;
          }
          resolve(book);
        }).catch(err => reject(err));
      });
    },
    addBook({ commit }, book) {
      const url = '/books/create';
      return new Promise((resolve, reject) => {
        BackendRequest.post(url, JSON.stringify(book))
          .then((res) => {
            const nBook = res.data;
            commit('addNewBook', nBook);
            resolve(nBook);
          })
          .catch(err => reject(err));
      });
    },
    async updateBook({ commit }, book) {
      const url = '/books/update';
      return new Promise((resolve, reject) => {
        BackendRequest.put(url, JSON.stringify(book))
          .then((res) => {
            const nBook = res.data;
            commit('setUpdateBook', nBook);
            resolve(nBook);
          })
          .catch(err => reject(err));
      });
    },
    async deleteSelectedBooks({ commit, state }) {
      const selectedBookIds = [];
      state.books.map((item) => {
        if (item.checked) {
          selectedBookIds.push(item.id);
        }
        return selectedBookIds;
      });
      if (selectedBookIds.length) {
        const url = `/books/delete/${JSON.stringify(selectedBookIds)}`;
        return new Promise((resolve, reject) => {
          BackendRequest.delete(url)
            .then(() => {
              commit('removeSelectedBooks');
              resolve();
            })
            .catch(err => reject(err));
        });
      }
      return false;
    },
    deleteBook({ commit, state }, bookId) {
      const book = state.books.find(item => item.id === bookId);
      if (book) {
        const url = `/books/delete/${bookId}`;
        return new Promise((resolve, reject) => {
          BackendRequest.delete(url)
            .then(() => {
              commit('removeSelectedBooks');
              resolve();
            })
            .catch(err => reject(err));
        });
      }
      return false;
    },
    markAsFavorite(context, bookIds) {
      return new Promise((resolve, reject) => {
        const params = {
          book_ids: bookIds,
        };
        BackendRequest.post('/favbooks/create', JSON.stringify(params)).then(() => {
          resolve();
        }).catch(() => reject());
      });
    },
  },
};

export default bookStore;
