import { loadFileAsync } from '@/util/file.helper';

/**
 * List of supported Languages
 * Only ISO 639-1 Language Codes are allowed for locales.
 * See https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
export const supportedLanguages = [
  { locale: 'en', englishName: 'English', localizedName: 'English' },
  { locale: 'fr', englishName: 'French', localizedName: 'Français' },
  { locale: 'de', englishName: 'German', localizedName: 'Deutsch' },
  { locale: 'pt', englishName: 'Portuguese', localizedName: 'Português' },
  {
    locale: 'ar', englishName: 'Arabic', localizedName: 'العربية', textDirection: 'rtl',
  },
  { locale: 'yo', englishName: 'Yoruba', localizedName: 'Yorùbá' },
  { locale: 'ha', englishName: 'Hausa', localizedName: 'Hausa' },
  { locale: 'ig', englishName: 'Igbo', localizedName: 'Igbo' },
  { locale: 'sw', englishName: 'Swahili', localizedName: 'Kiswahili' },
];

/**
 * @function
 * Check if a locale is part of supported languages or not
 *
 * @param {string} locale - The input locale to check
 * @return {boolean} True if the locale is supported, False otherwise
 */
export function isLocaleSupported(locale) {
  return supportedLanguages.filter(lang => lang.locale === locale).length > 0;
}

// Array of already loaded translation messages
const loadedLocales = [];

// Asynchronous load local messages
async function loadLocaleMessageAsync(i18n, locale) {
  return new Promise(async (resolve, reject) => {
    if (loadedLocales.includes(locale)) {
      // language was already loaded
      resolve(true);
    } else {
      loadFileAsync(`@/i18n/lang/${locale}`)
        .then((messages) => {
          i18n.setLocaleMessage(locale, messages.default);
          loadedLocales.push(locale);
          resolve(true);
        })
        .catch(error => reject(error));
    }
  });
}

// Synchronous load local messages
function loadLocaleMessageSync(i18n, locale) {
  if (!loadedLocales.includes(locale)) {
    // `require()` is used for synchronous loading of modules. ESLint is temporarily
    // disabled here in order to use `require()` for dynamic loading of locale messages.
    /* eslint-disable global-require, import/no-dynamic-require */
    const messages = require(`@/i18n/lang/${locale}`);
    /* eslint-enable import/no-dynamic-require */
    i18n.setLocaleMessage(locale, messages.default);
    loadedLocales.push(locale);
  }
}

/**
 * Change the site language to the specified locale
 *
 * @param {Object} i18n vue-i18n object handle
 * @param {String} locale The locale to set
 */
export async function setLocale(i18n, locale) {
  if (!isLocaleSupported(locale) || (i18n.locale === locale)) return Promise.resolve(i18n.locale);

  try {
    await loadLocaleMessageAsync(i18n, locale);
    i18n.locale = locale;
    document.querySelector('html').setAttribute('lang', locale);
    return locale;
  } catch (e) {
    return i18n.locale;
  }
}

// Detect the locale of the most preferred language configured in user's browser
function getBrowserLocale() {
  let locale = null;
  if (window) {
    const lang = (window.navigator.languages && window.navigator.languages[0])
      || window.navigator.language || window.navigator.browserLanguage
      || window.navigator.userLanguage || window.navigator.systemLanguage;
    if (lang) [locale] = lang.toLowerCase().split(/(-|_)/);
  }
  return locale;
}

/**
 * Initialize i18n object with the user preferred language and fallback locale
 *
 * @param {Object} i18n vue-i18n object handle
 * @param {String} fallbackLocale Fallback locale in case auto detection fails
 */
export async function initLocale(i18n, fallbackLocale) {
  // load first the fallback locale messages
  i18n.locale = fallbackLocale;
  i18n.fallbackLocale = fallbackLocale;
  loadLocaleMessageSync(i18n, fallbackLocale);
  // set the preferred locale
  await setLocale(i18n, getBrowserLocale());
}
