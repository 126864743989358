const bookRoutes = [
  {
    path: 'book',
    component: () => import('@/components/layout/RouterView.vue'),
    children: [
      {
        path: '',
        name: 'book',
        redirect: { name: 'bke-book-list' },
      },
      {
        path: 'list',
        name: 'bke-book-list',
        component: () => import('./ListBook.vue'),
      },
      {
        path: 'new',
        name: 'bke-book-new',
        component: () => import('./CreateBook.vue'),
      },
      {
        path: ':book_id',
        name: 'bke-book-view',
        props: true,
        component: () => import('./ViewBook.vue'),
      },
      {
        path: ':book_id/edit',
        name: 'bke-book-edit',
        props: true,
        component: () => import('./EditBook.vue'),
      },
    ],
  },
];

export default bookRoutes;
