import BackendRequest from '@/util/HttpRequest/api.backend';

const testStore = {
  namespaced: true,

  state: {
    test_id: '',
    testQuizzes: [],
    timeAllowed: null,
    testScore: 0,
    resultSummary: [],
  },

  getters: {
    test_id: state => state.test_id,
    testQuizzes: state => state.testQuizzes,
    timeAllowed: state => state.timeAllowed,
    testScore: state => state.testScore,
    resultSummary: state => state.resultSummary,
  },

  actions: {
    // create a test
    createTest(context, testDetails) {
      return new Promise((resolve, reject) => {
        BackendRequest.post('/tests/create', testDetails)
          .then((response) => {
            if (response.status === 201) {
              const { id } = response.data;
              context.commit('createTestId', id);
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // get test created by user relating to a particular boom
    getTestCreatedByUser(context, testId) {
      return new Promise((resolve, reject) => {
        BackendRequest.get(`/tests/read/${testId}`)
          .then((res) => {
            if (res.status === 200) {
              const testQuizzes = res.data[0].quizzes;
              const timeAllowed = res.data[0].time_allowed;
              context.commit('getAllTestQuizzes', testQuizzes);
              context.commit('saveTimeallowed', timeAllowed);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // grade test
    gradeTest(context, testDetails) {
      return new Promise((resolve, reject) => {
        BackendRequest.put('/tests/grade', testDetails)
          .then((response) => {
            if (response.status === 200) {
              localStorage.removeItem('time_spent');
              const testScore = response.data.score;
              context.commit('saveTestScore', testScore);
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // read the result of the test for student
    getResultOfTest(context, testId) {
      return new Promise((resolve, reject) => {
        BackendRequest.get(`/tests/read/${testId}`)
          .then((response) => {
            if (response.status === 200) {
              const resultSummary = response.data[0];
              context.commit('saveResulSummary', resultSummary);
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

  },

  mutations: {
    createTestId: (state, id) => { state.test_id = id; },
    getAllTestQuizzes: (state, testQuizzes) => { state.testQuizzes = testQuizzes; },
    saveTimeallowed: (state, timeAllowed) => { state.timeAllowed = timeAllowed; },
    saveTestScore: (state, testScore) => { state.testScore = testScore; },
    saveResulSummary: (state, resultSummary) => { state.resultSummary = resultSummary; },
  },
};

export default testStore;
