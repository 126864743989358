import quizRoutes from './views/quiz/router';
import bookRoutes from './views/book/router';

const StagingRoutes = [
  {
    path: '/staging',
    component: () => import('./views/Navigation.vue'),
    children: [
      {
        path: '',
        name: 'staging',
        component: () => import('./views/Staging.vue'),
      },
      {
        path: 'sample-md',
        name: 'sample-md',
        component: () => import(/* webpackChunkName: "sampleMd" */ './views/sample.md'),
      },

      ...quizRoutes,
      ...bookRoutes,
      {
        path: 'quizzes',
        name: 'quizzes',
        component: () => import(/* webpackChunkName: "quizzes" */ './views/quizzes/Quizzes.vue'),
      },
      {
        path: 'book/:id/quiz/add',
        name: 'addQuiz',
        component: () => import(/* webpackChunkName: "addQuizzes" */ './views/quizzes/AddQuiz.vue'),
      },
      {
        path: 'book/:id/quiz/edit',
        name: 'editQuiz',
        component: () => import(/* webpackChunkName: "editQuizzes" */ './views/quizzes/EditQuiz.vue'),
      },
      {
        path: 'file-browser',
        component: () => import(/* webpackChunkName: "fileBrowser" */ './views/file/FileBrowser.vue'),
        children: [
          {
            path: '', redirect: { name: 'drive' },
          },
          {
            path: 'upload',
            name: 'upload',
            component: () => import(/* webpackChunkName: "fileUpload" */ './views/file/components/FileUpload.vue'),
          },
          {
            path: 'drive',
            name: 'drive',
            component: () => import(/* webpackChunkName: "fileLibrary" */ './views/file/components/FileLibrary.vue'),
          },
          {
            path: 'drive/:id/:name',
            name: 'folder',
            component: () => import(/* webpackChunkName: "fileLibraryId" */ './views/file/components/FileLibrary.vue'),
          },
        ],
      },

      {
        path: 'book/:book_id/test',
        component: () => import(/* webpackChunkName: "test" */ './views/tests/Test.vue'),
        children: [
          {
            path: '',
            name: 'TestInstruction',
            component: () => import(/* webpackChunkName: "test-instruction" */ './views/tests/test-pages/TestInstruction.vue'),
          },
          {
            path: ':test_id',
            name: 'TakeTest',
            component: () => import(/* webpackChunkName: "take-test" */ './views/tests/test-pages/TakeTest.vue'),
          },
          {
            path: 'result/:test_id',
            name: 'TestResult',
            component: () => import(/* webpackChunkName: "test-result" */ './views/tests/test-pages/TestResult.vue'),
          },
        ],
      },

    ],
  },
];

export default StagingRoutes;
