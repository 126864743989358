<template>
  <footer class="container-fluid">
    <div class="container-lg mx-auto p-0 py-2">
      <span><small>Copyright &copy; {{ new Date().getFullYear() }} wakaru.org. All Rights Reserved.</small></span>
    </div>
  </footer>
</template>

<script>
export default { name: 'Copyright' };
</script>
