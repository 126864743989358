import HttpRequest from '@/util/HttpRequest';

const baseUrl = 'https://my-json-server.typicode.com/t4top/json_db';

// Get All Quizes from remote service using Html5 Fetch API
export async function getAllQuizes() {
  const url = `${baseUrl}/wakaru_allquizzes`;
  try {
    const response = await HttpRequest(url);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
}
// Deleted Multiple Quizes by their Ids
export async function deleteQuizes(quizIds) {
  return new Promise((resolve) => {
    if (!quizIds) {
      return resolve(false);
    }
    return resolve(true);
  });
}
// add array/multiple quizes
export async function addMultipleQuizes(jsonQuizes) {
  return new Promise((resolve) => {
    if (!jsonQuizes) {
      return resolve(false);
    }
    return resolve(true);
  });
}
// update existing quizzes
export async function updateQuiz(jsonQuizes) {
  return new Promise((resolve) => {
    if (!jsonQuizes) {
      return resolve(false);
    }
    return resolve(true);
  });
}
