import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { initLocale } from './lang';

// default fallback locale is 'en'
const defaultLocale = 'en';

Vue.use(VueI18n);
const i18n = new VueI18n();

// Detect the locale preferred by the user and set it
initLocale(i18n, defaultLocale);

export default i18n;
