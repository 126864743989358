import store from '@/store';
import { checkAuthStatus } from '@/store/modules/auth/firebase.config';
import userRoutes from './user/router';
import testRoutes from './test/router';
import backendRoutes from './backend/router';

const protectedRoutes = [
  ...userRoutes,
  ...testRoutes,
  ...backendRoutes,
];

function handlePermissionCheck(route) {
  route.beforeEnter = async (to, from, next) => {
    const { auth } = store.state;

    if (!auth.isInitialized) await checkAuthStatus();

    if (auth.isLoggedIn) {
      if (auth.user.emailVerified || (to.meta && to.meta.requireValidEmail === false)) next();
      else next({ name: 'verify-email' });
    } else {
      next({ name: 'login', query: { redirect: to.fullPath } });
    }
  };
  if (route.children) route.children.map(handlePermissionCheck); // Recursion
}

protectedRoutes.map(handlePermissionCheck);

export default protectedRoutes;
