<!-- This was mostly taken from https://github.com/vuejs/vuepress/tree/master/packages/%40vuepress/plugin-back-to-top. -->
<template>
  <transition name="fade">
    <svg v-if="show" class="go-to-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.484 28.284" @click="scrollToTop" >
      <g transform="translate(-229 -126.358)">
        <rect fill="currentColor" width="35" height="5" rx="2" transform="translate(229 151.107) rotate(-45)" />
        <rect fill="currentColor" width="35" height="5" rx="2" transform="translate(274.949 154.642) rotate(-135)" />
      </g>
    </svg>
  </transition>
</template>

<script>
import { debounce } from '@/util/util';

export default {
  name: 'BackToTop',

  data() {
    return {
      scrollTop: null,
      threshold: 300,
    };
  },

  computed: {
    show() {
      return this.scrollTop > this.threshold;
    },
  },

  mounted() {
    this.scrollTop = this.getScrollTop();
    if (window) window.addEventListener('scroll', this.handleScroll, { passive: true });
  },
  beforeDestroy() {
    if (window) window.removeEventListener('scroll', this.handleScroll, { passive: true });
  },

  methods: {
    handleScroll: debounce(function handleScroll() {
      this.scrollTop = this.getScrollTop();
    }, 100),

    getScrollTop() {
      return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    },

    scrollToTop() {
      if (window) window.scrollTo({ top: 0, behavior: 'smooth' });
      this.scrollTop = 0;
    },
  },
};

</script>

<style lang='stylus' scoped>

.go-to-top
  cursor pointer
  position fixed
  bottom 2rem
  right 2.5rem
  width 2rem
  color var(--color_backtotop)
  z-index 1000

  &:hover
    color var(--color_backtotop_hover)

.fade-enter-active, .fade-leave-active
  transition opacity 0.3s

.fade-enter, .fade-leave-to
  opacity 0

</style>
