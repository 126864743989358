const schoolRoutes = [
  {
    path: 'school',
    component: () => import('@/components/layout/RouterView.vue'),
    children: [
      {
        path: '',
        name: 'school',
        redirect: { name: 'bke-school-list' },
      }, {
        path: 'list',
        name: 'bke-school-list',
        component: () => import('./ListSchool.vue'),
      },
      {
        path: 'new',
        name: 'bke-school-new',
        component: () => import('./CreateSchool.vue'),
      },
      {
        path: ':school_id',
        name: 'bke-school-view',
        component: () => import('./ViewSchool.vue'),
      },
      {
        path: ':school_id/edit',
        name: 'bke-school-edit',
        component: () => import('./EditSchool.vue'),
      },
    ],
  },
];

export default schoolRoutes;
