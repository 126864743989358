import BackendRequest from '@/util/HttpRequest/api.backend';

const schoolStore = {
  namespaced: true,
  state: {
    schools: [],
  },

  mutations: {
    setSchools(state, schools) {
      if (schools) state.schools = [...schools];
    },
    addSchools(state, schools) {
      if (schools && schools.length) {
        schools.forEach((school) => {
          // update if the school is already in the array or prepend otherwise
          const idx = state.schools.findIndex(item => item.id === school.id);
          if (idx >= 0) state.schools[idx] = school;
          else state.schools.unshift(school);
        });
      }
    },
    updateSchools(state, schools) {
      if (schools && schools.length) state.schools = state.schools.map(x => schools.find(y => y.id === x.id) || x);
    },
    removeSchools(state, schoolIds) {
      if (schoolIds && schoolIds.length) state.schools = state.schools.filter(x => schoolIds.indexOf(x.id) < 0);
    },
  },

  actions: {
    getAllSchools({ commit }) {
      const url = '/schools/read';
      return new Promise((resolve, reject) => {
        BackendRequest.get(url)
          .then((res) => {
            commit('setSchools', res.data.schools);
            resolve(true);
          }).catch(() => reject());
      });
    },
    async getSchoolById({ dispatch }, schoolId) {
      return new Promise((resolve, reject) => {
        dispatch('getSchoolsByIds', [schoolId]).then((schools) => {
          const school = (schools && schools.length) ? schools[0] : {};
          resolve(school);
        }).catch(() => reject());
      });
    },
    getSchoolsByIds({ dispatch, state, commit }, schoolIds) {
      return new Promise((resolve, reject) => {
        const schools = state.schools.filter(x => schoolIds.indexOf(x.id) >= 0);
        if (schools.length === schoolIds.length) resolve(schools);
        else {
          const strSchoolIds = schoolIds.join(',');
          const url = `/schools/read/${strSchoolIds}`;
          BackendRequest.get(url)
            .then((res) => {
              commit('addSchools', res.data.schools);
              resolve(res.data.schools);
            })
            .catch(() => reject());

          // get all schools as well if local school array is empty
          if (state.schools.length === 0) dispatch('getAllSchools').catch(() => { /* ignore */ });
        }
      });
    },
    addSchool({ commit }, schools) {
      return new Promise((resolve, reject) => {
        BackendRequest.post('/schools/create', JSON.stringify(schools)).then((res) => {
          commit('addSchools', res.data);
          resolve(true);
        }).catch(err => reject(err));
      });
    },
    updateSchool({ commit }, schools) {
      return new Promise((resolve, reject) => {
        BackendRequest.put('/schools/update', JSON.stringify(schools)).then((res) => {
          commit('updateSchools', res.data);
          resolve(true);
        }).catch(err => reject(err));
      });
    },
    deleteSchools({ commit }, schoolIds) {
      const strSchoolIds = schoolIds.join(',');
      const url = `/schools/delete/${strSchoolIds}`;
      return new Promise((resolve, reject) => {
        BackendRequest.delete(url)
          .then(() => {
            commit('removeSchools', schoolIds);
            resolve();
          })
          .catch(err => reject(err));
      });
    },
  },
};

export default schoolStore;
