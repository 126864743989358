import BackendRequest from '@/util/HttpRequest/api.backend';

const getters = {
  allQuizzes: state => state.quizzes,
  quizToEdit: state => state.quizToEdit[0],
  errInfo: state => state.errInfo,
  errored: state => state.errored,
  loading: state => state.loading,
  successMessage: state => state.successMessage,
};
const actions = {
  // CREAT QUIZZES
  // Make a post request to the api endpoint, to create a quiz
  addQuiz(context, quiz) {
    BackendRequest.post('/quizzes/create', quiz)
      .then((res) => {
        if (res.status === 201) {
          const addedQuiz = JSON.parse(quiz);
          addedQuiz.forEach((element) => {
            context.commit('addQuiz', element);
          });
          const message = 'Quiz created';
          context.commit('handleSuccess', message);
        }
      })
      .catch((err) => {
        context.commit('handleError', err);
      })
      .finally(context.commit('resetLoading'));
  },

  // READ QUIZZES
  // Make a get request to the api endpoint, to get all quizzes
  getAllQuizzes(context) {
    BackendRequest.get('/quizzes/read')
      .then((res) => {
        const quizzes = res.data;
        context.commit('getAllQuizzes', quizzes);
      })
      .catch((err) => {
        context.commit('handleError', err);
      })
      .finally(context.commit('resetLoading'));
  },

  // UPDATE QUIZZES
  // Make an update request to the api enpoint, to edit a quiz
  setQuizToEdit(context, id) {
    context.commit('setQuizToEdit', id);
  },
  SETTER_QUIZ_EDIT_QUESTION(context, editedQuestion) {
    context.commit('setterQuizEditQuestion', editedQuestion);
  },
  SETTER_QUIZ_EDIT_OPTIONS(context, editedOptions) {
    context.commit('setterQuizEditOptions', editedOptions);
  },
  SETTER_QUIZ_EDIT_POINT(context, editedPoint) {
    context.commit('setterQuizEditPoint', editedPoint);
  },
  SETTER_QUIZ_EDIT_EXPLANATION(context, editedExplanation) {
    context.commit('setterQuizEditExplanation', editedExplanation);
  },
  // DELETE QUIZZES
  // Make a delete request to the api endpoint, to delete a quiz
  deleteQuiz(context, id) {
    BackendRequest.delete(`/quizzes/delete/${id}`)
      .then((res) => {
        if ((res.status === 200) || (res.status === 204)) {
          context.commit('deleteQuiz', id);
          const message = 'Quiz deleted';
          context.commit('handleSuccess', message);
        }
      })
      .catch((err) => {
        context.commit('handleError', err);
      })
      .finally(context.commit('resetLoading'));
  },
};
const mutations = {
  // Mutation that updates the state of the app
  addQuiz: (state, quiz) => { state.quizzes.push(quiz); },
  getAllQuizzes: (state, data) => { if (data && data.quizzes) state.quizzes = [...data.quizzes]; },
  setQuizToEdit: (state, id) => {
    state.quizzes.forEach((quiz) => {
      if (quiz.id === id) {
        state.quizToEdit.unshift(quiz);
        state.editQuiz = true;
      }
    });
  },
  setterQuizEditQuestion: (state, editedQuestion) => { state.quizToEdit[0].question = editedQuestion; },
  setterQuizEditOptions: (state, editedOptions) => { state.quizToEdit[0].options = editedOptions; },
  setterQuizEditPoint: (state, editedPoint) => { state.quizToEdit[0].point = editedPoint; },
  setterQuizEditExplanation: (state, editedExplanation) => { state.quizToEdit[0].explanation = editedExplanation; },
  deleteQuiz: (state, id) => {
    const idArray = id.split(',');
    idArray.forEach((elId) => { state.quizzes = state.quizzes.filter(quiz => quiz.id !== elId); });
  },
  handleError: (state, err) => {
    state.errInfo = err;
    state.errored = true;
  },
  resetLoading: (state) => { state.loading = false; },
  handleSuccess: (state, message) => {
    state.successMessage = message;
    state.errored = false;
  },
};

const state = {
  quizzes: [],
  quizToEdit: [],
  editQuiz: false,
  errInfo: '',
  loading: true,
  errored: false,
  successMessage: '',
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
