const testRoutes = [
  {
    path: '/book/:book_id/test',
    props: true,
    components: {
      navbar: () => import('./components/NavBar.vue'),
      default: () => import('@/components/layout/RouterView.vue'),
    },
    children: [
      {
        path: '',
        name: 'test-info',
        component: () => import('./TestInfo.vue'),
      },
      {
        path: 'instruction',
        name: 'test-instruction',
        component: () => import('./bookTest/TestInstruction.vue'),
      },
      {
        path: 'sample',
        name: 'sampletest-question',
        component: () => import('./sampleTest/SampleTestQuestion.vue'),
      },
      {
        path: 'sample/instruction',
        name: 'sampletest-instruction',
        component: () => import('./sampleTest/SampleTestInstruction.vue'),
      },
      {
        path: 'sample/result',
        name: 'sampletest-result',
        component: () => import('./sampleTest/SampleTestResult.vue'),
      },
      {
        path: 'sample/:question_no',
        redirect: { name: 'sampletest-question' },
      },
      {
        path: ':test_id',
        name: 'test-question',
        component: () => import('./bookTest/TestQuestion.vue'),
      },
      {
        path: ':test_id/result',
        name: 'test-result',
        component: () => import('./bookTest/TestResult.vue'),
      },
      {
        path: ':test_id/:question_no',
        redirect: { name: 'test-question' },
      },
    ],
  },
];

export default testRoutes;
