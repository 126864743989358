import BackendRequest from '@/util/HttpRequest/api.backend';

const testStore = {
  namespaced: true,
  state: {
    tests: [],
  },

  mutations: {
    setTests(state, tests) {
      if (tests) state.tests = [...tests];
    },
    updateTest(state, test) {
      if (test) {
        // update if the test is already in the array or prepend otherwise
        const idx = state.tests.findIndex(item => item.id === test.id);
        if (idx >= 0) state.tests[idx] = { ...test };
        else state.tests.unshift({ ...test });
      }
    },
  },

  actions: {
    // create a test
    createTest({ commit }, testDetails) {
      return new Promise((resolve, reject) => {
        BackendRequest.post('/tests/create', JSON.stringify(testDetails))
          .then((res) => {
            const test = res.data;
            commit('updateTest', test);
            resolve(test);
          })
          .catch(() => reject());
      });
    },

    // grade test
    gradeTest({ commit }, gradeDetails) {
      return new Promise((resolve, reject) => {
        BackendRequest.put('/tests/grade', JSON.stringify(gradeDetails))
          .then((res) => {
            const test = res.data;
            commit('updateTest', test);
            resolve(test);
          })
          .catch(() => reject());
      });
    },

    // get a test by ID
    getTestById({ dispatch, commit, state }, testId) {
      return new Promise((resolve, reject) => {
        let tests = state.tests.filter(x => x.id === testId);
        if (tests.length) resolve(tests[0]);
        else {
          BackendRequest.get(`/tests/read/${testId}`)
            .then((res) => {
              ({ tests } = res.data);
              const test = (tests && tests.length) ? tests[0] : {};
              commit('updateTest', test);
              resolve(test);
            })
            .catch(() => reject());

          // get all tests as well if local test array is empty
          if (state.tests.length === 0) dispatch('getUserTests').catch(() => { /* ignore */ });
        }
      });
    },

    // get all tests of current user
    getUserTests({ commit }) {
      return new Promise((resolve, reject) => {
        BackendRequest.get('/tests/read')
          .then((res) => {
            const { tests } = res.data;
            commit('setTests', tests);
            resolve();
          })
          .catch(() => reject());
      });
    },

    // get all tests of current user related to a book
    getUserTestsByBookId({ dispatch, commit, state }, bookId) {
      return new Promise((resolve, reject) => {
        let tests = state.tests.filter(x => x.book.id === bookId);
        if (tests.length) resolve(tests);
        else {
          BackendRequest.get(`/tests/read?book_id=${bookId}`)
            .then((res) => {
              ({ tests } = res.data);
              tests.forEach((test) => {
                commit('updateTest', test);
              });
              resolve(tests);
            })
            .catch(() => reject());

          // get all tests as well if local test array is empty
          if (state.tests.length === 0) dispatch('getUserTests').catch(() => { /* ignore */ });
        }
      });
    },

  },
};

export default testStore;
