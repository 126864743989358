import Vue from 'vue';
import Vuex from 'vuex';
import { modules, dispatchActionToAllModules } from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  strict: process.env.NODE_ENV !== 'production',
});

export default store;

// Call the 'init' action of every module
dispatchActionToAllModules(store, 'init');
