import Ajax from './Ajax';
import { extend } from '../util';

const AjaxInstance = new Ajax();

// Create HttpRequest as new instance of Ajax.
function HttpRequest(url, config) {
  // update configuration option if provided
  if (config) AjaxInstance.setConfig(config);

  // Defaults to a GET request if url parameter is set.
  if (url) {
    return AjaxInstance.get(url);
  }
}

// Copy Ajax.prototype to the instance
extend(HttpRequest, AjaxInstance, false);

// Factory function for creating new HttpRequest instances
HttpRequest.create = function create(config) {
  return new Ajax(config);
};

// Expose all interface for sending multiple requests simultaneously
HttpRequest.all = function all(promises) {
  return Promise.all(promises);
};

export default HttpRequest;
