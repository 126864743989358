const bookRoutes = [
  {
    path: '/book',
    components: {
      navbar: () => import('@/components/section/NavBar.vue'),
      footer: () => import('@/components/section/Footer.vue'),
      default: () => import('@/components/layout/RouterView.vue'),
    },
    children: [
      {
        path: '',
        redirect: { name: 'book-cat-list' },
      },
      {
        path: 'cat',
        name: 'book-cat-list',
        component: () => import('./BookCategoryList.vue'),
      },
      {
        path: ':book_id',
        name: 'book-preview',
        props: true,
        component: () => import('./BookPreview.vue'),
      },
      {
        path: 'cat/:cat_id',
        name: 'book-cat',
        props: true,
        component: () => import('./BookCategory.vue'),
      },
    ],
  },
];

export default bookRoutes;
