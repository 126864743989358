/**
 * Global configurations for the whole site
 */

const appConfig = {

  // Configurations for Firebase Authentication
  firebaseConfig: {
    apiKey: 'AIzaSyBFx3l8GAL0Ei2rWdqIDtoTqpSURYiH2Uc',
    authDomain: 'wakaru-staging-587fa.firebaseapp.com',
    databaseURL: 'https://wakaru-staging-587fa.firebaseio.com',
    projectId: 'wakaru-staging-587fa',
    storageBucket: 'wakaru-staging-587fa.appspot.com',
    messagingSenderId: '190057735681',
    appId: '1:190057735681:web:f425284739bf25b9c84200',
    measurementId: 'G-BE0N90BYG4',
  },

  // Default Language Locale
  defaultLocale: 'en',

  // School grade range
  schoolGrade: {
    min: 1,
    max: 13,
  },

  // Points range
  point: {
    min: 1,
    max: 10,
  },

  // Max. possible no of options in a quiz
  maxQuizOptionsCount: 5,

  // No of questions per test
  testQuestionsCount: 3, // should be 15. Set to 3 since there are only few sample quizzes in the DB for now

  // Time duration (in seconds) allowed for a test
  testAllowedDuration: 1200,

  // Remaining time (in seconds) when the user will be warned of imminent timeout.
  // The user will be warned by changing the countdown timer to red color
  testWarnUserTimer: 60,
};

export default appConfig;
