import BackendRequest from '@/util/HttpRequest/api.backend';

const quizStore = {
  namespaced: true,
  state: {
    quizzes: {},
  },

  mutations: {
    setAllQuizzes(state, { bookId, data }) {
      if (data && data.quizzes) state.quizzes[bookId] = [...data.quizzes];
    },
    addQuizzes(state, { bookId, quizzes }) {
      if (quizzes && quizzes.length) {
        if (state.quizzes[bookId]) {
          quizzes.forEach((quiz) => {
            // update if the quiz is already in the array or prepend otherwise
            const idx = state.quizzes[bookId].findIndex(item => item.id === quiz.id);
            if (idx >= 0) state.quizzes[bookId][idx] = quiz;
            else state.quizzes[bookId].unshift(quiz);
          });
        } else state.quizzes[bookId] = [...quizzes];
      }
    },
    updateQuizzes(state, { bookId, quizzes }) {
      if (quizzes && quizzes.length && state.quizzes[bookId]) state.quizzes[bookId] = state.quizzes[bookId].map(x => quizzes.find(y => y.id === x.id) || x);
    },
    removeQuizzes(state, { bookId, quizIds }) {
      if (quizIds && quizIds.length && state.quizzes[bookId]) state.quizzes[bookId] = state.quizzes[bookId].filter(x => quizIds.indexOf(x.id) < 0);
    },
  },

  actions: {
    async getAllQuizzesOfBook({ commit }, bookId) {
      const url = `/quizzes/read?book_id=${bookId}`;
      return new Promise((resolve, reject) => {
        BackendRequest.get(url)
          .then((res) => {
            commit('setAllQuizzes', { bookId, data: res.data });
            resolve();
          })
          .catch(() => reject());
      });
    },
    async getQuizzById({ dispatch, commit, state }, { bookId, quizId }) {
      return new Promise((resolve, reject) => {
        let quiz = null;
        if (state.quizzes[bookId]) quiz = state.quizzes[bookId].find(item => item.id === quizId);
        if (quiz) resolve(quiz);
        else {
          const url = `/quizzes/read/${quizId}`;
          BackendRequest.get(url)
            .then((res) => {
              commit('addQuizzes', { bookId, quizzes: res.data.quizzes });
              quiz = (res.data.quizzes && res.data.quizzes.length) ? res.data.quizzes[0] : {};
              resolve(quiz);
            })
            .catch(() => reject());

          // get all quizzes for the book as well if local array is empty
          if (bookId && !state.quizzes[bookId]) dispatch('getAllQuizzesOfBook', bookId).catch(() => { /* ignore */ });
        }
      });
    },
    async addQuizzes({ commit }, { bookId, quizzes }) {
      const url = '/quizzes/create';
      return new Promise((resolve, reject) => {
        BackendRequest.post(url, JSON.stringify(quizzes))
          .then((res) => {
            commit('addQuizzes', { bookId, quizzes: res.data });
            resolve();
          })
          .catch(() => reject());
      });
    },
    async updateQuizzes({ commit }, { bookId, quizzes }) {
      const url = '/quizzes/update';
      return new Promise((resolve, reject) => {
        BackendRequest.put(url, JSON.stringify(quizzes))
          .then((res) => {
            commit('updateQuizzes', { bookId, quizzes: res.data });
            resolve();
          })
          .catch(() => reject());
      });
    },
    async deleteQuizzes({ commit }, { bookId, quizIds }) {
      const strQuizIds = quizIds.join(',');
      const url = `/quizzes/delete/${strQuizIds}`;
      return new Promise((resolve, reject) => {
        BackendRequest.delete(url)
          .then(() => {
            commit('removeQuizzes', { bookId, quizIds });
            resolve();
          })
          .catch(() => reject());
      });
    },
  },
};

export default quizStore;
